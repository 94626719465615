@tailwind base;
@tailwind components;

/* disable viewport scaling on mobile: https://stackoverflow.com/a/62165035/1372110 */
/* following this line of argument: https://stackoverflow.com/questions/4389932/how-do-you-disable-viewport-zooming-on-mobile-safari#comment45014494_4389932 */
body {
  touch-action: pan-x pan-y;
}

/* React Swiper specific styles: these are predefined classes, so we're using Tailwind's @apply directive for styling */
.swiper-button-prev,
.swiper-button-next {
  @apply h-full top-0 w-24;
}

.swiper-button-prev {
  @apply bg-gradient-to-r from-white;
}

.swiper-button-next {
  @apply bg-gradient-to-l from-white;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  @apply bg-teal-500 w-10 h-10 rounded-full text-sm text-white shadow;
  content: '';
  background-image: url('static/icon-arrow-right-white.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.swiper-button-prev:after {
  padding: 0.7rem 1rem;
  @apply ml-4;
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.swiper-button-next:after {
  padding: 0.7rem 1.1rem;
  @apply mr-4;
}

.swiper-button-prev {
  @apply left-0;
}

.swiper-button-next {
  @apply right-0;
}

/* Swiper as Horizontal Nav */
/* For Media Opportunities page */
/* Note: Can be made more generic if reusing in other areas */

#opportunities .swiper-button-prev:after,
#opportunities .swiper-button-next:after {
  @apply bg-teal-600 w-8 h-8 shadow-none;
  background-image: none;
  mask: url('static/icon-chevron-down.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
  padding: 0;
  margin: 0;
}

#opportunities .swiper-button-next:after {
  transform: rotate(270deg);
}

#opportunities .swiper-button-prev:after {
  transform: rotate(90deg);
}

#opportunities .swiper-button-next {
  @apply justify-end;
}

#opportunities .swiper-button-prev {
  @apply justify-start;
}

@media (min-width: 968px) {
  #opportunities .swiper-button-next {
    display: none;
  }
}

/* React Tags specific styles: these are predefined classes, so we're using Tailwind's @apply directive for styling */
.react-tags {
  @apply relative px-2 py-0 pb-0 mt-2 flex-1 block w-full bg-white border border-gray-200 min-w-0 text-sm cursor-text;
}

.react-tags.is-focused {
  @apply ring-teal-500 border-teal-500;
}

.react-tags__selected {
  @apply inline;
}

.react-tags__search {
  @apply inline-block py-2 px-2 pl-0 mb-1 w-32 relative;
}

.react-tags__search-input {
  @apply min-w-full m-1.5 mb-0.5 p-0 text-xs border-0 outline-none;
}

.react-tags__search-input::-ms-clear {
  @apply hidden;
}

.react-tags__suggestions {
  @apply absolute top-full -left-px z-20;
}

.react-tags__suggestions ul {
  @apply my-4 list-none bg-white border border-gray-200;
}

.react-tags__suggestions li {
  @apply px-4 py-2 text-sm whitespace-nowrap;
}

.react-tags__suggestions li mark {
  @apply bg-transparent font-bold;
}

.react-tags__suggestions li:hover {
  @apply cursor-pointer bg-gray-100;
}

.react-tags__suggestions li.is-active {
  @apply bg-gray-100;
}

.react-tags__suggestions li.is-disabled {
  @apply opacity-50 cursor-auto;
}

@tailwind utilities;


/* Tailwind hacks/updates */
.break-words {
  word-break: break-word;
  overflow-wrap: break-word;
}